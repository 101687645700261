.page-header {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  height: 290px;
  margin-bottom: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;

  h1 {
    display: inline-block;
    float: left;
    clear: both;
    min-width: 50%;
    max-width: 100%;
    color: @font-color-light;
    background: @color-primary;
    height: @title-height;
    line-height: @title-height;
    margin: 0;
    padding: 0 @grid-gutter-width;
    position: relative;
    bottom: 0;
    -webkit-transform: translateY(@title-height/2);
    -ms-transform: translateY(@title-height/2);
    transform: translateY(@title-height/2);

    img, span {
      float: left;
    }

    @media (max-width: @screen-xs - 1) {
      padding-left: 10px;
      font-size: 20px;
    }
  }

  .breadcrumb {
    -webkit-transform: translateY(@title-height/2);
    -ms-transform: translateY(@title-height/2);
    transform: translateY(@title-height/2);
    text-transform: uppercase;
  }

  &-despliegues {
    //background-image: url(images/despliegues-header-bg.jpg);

    h1 {
      text-transform: uppercase;
    }
  }

  &-despliegue {
    //background-image: url(images/despliegue-header-bg.jpg);
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  &-has-breadcrumbs {
    h1 {
      background: @color-secondary;
    }
  }

  &-has-icon {
    h1 {
      padding-left: 0;
      background: @color-secondary;

      img {
        height: @title-height;
        width: @title-height;
        padding: 20px;
        margin-right: @grid-gutter-width;
        background: @color-secondary-dark;

        @media (max-width: @screen-xs - 1) {
          height: 32px;
          width: 32px;
          padding: 2px;
          margin-right: @grid-gutter-width / 3;
        }
      }

      span {
        display: inline-block;
        max-width: calc(100% - 110px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: @screen-xs - 1) {
          max-width: calc(100% - 45px);
          font-size: 20px;
        }
      }
    }
  }

  &:not(.page-header-has-icon) {
    h1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: @screen-lg - 1) {
    h1 {
      min-width: calc((100%/3)*2);
    }
  }

  @media (max-width: @screen-md - 1) {
    margin-bottom: 55px;
    height: 150px;

    h1 {
      min-width: 100%;
    }
  }

  @media (max-width: @screen-xs - 1) {
    margin-bottom: 25px;
    height: 50px;

    h1 {
      min-width: 100%;

      height: 32px;
      line-height: 32px;
      transform: translateY(10px);
    }
  }
}