#body_wrapper {
  min-height: 100vh;
  position: relative;
}

.main-content {
  padding-top: 50px;
  padding-bottom: 80px;
}

.view {
  margin-top: 90px;
  padding-top: 20px;
}

.margin-top-content {
  padding-top: 50px;
}

.margin-bottom-content {
  padding-bottom: 50px;
}

.posts {
  height: 100%;
}

.app-body.to-top {
  padding-top: 0 !important;
}


@media (max-width: @screen-sm - 1) {
  .container {
    width: 100%;
  }
}

//@media (max-width: @screen-xs - 1) {
//  .main-content,
//  .main-content .container {
//    padding-left: 0;
//    padding-right: 0;
//  }
//}