.bloque-boton {
  text-align: center;
  padding: 30px 0 0 0;
  background: @bg-color-light;
  color: @font-color-dark;
  margin-top: 20px;
  box-shadow: @cards-box-shadow;
  transition: all 0.3s;

  &:hover {
    box-shadow: @cards-box-shadow-hover;
  }

  > a {

    &:focus, &:hover {
      text-decoration: none;

      .btn-secondary {
        background-color: darken(@color-secondary, 5);
        border-color: darken(@color-secondary, 5);
      }
    }
  }

  .b-bloque {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    img {
      max-height: 85px;
    }

    h4 {
      text-transform: uppercase;
      padding: 15px;
    }
  }

  .b-boton .btn {
    width: 100%;
    padding: 15px !important;
    text-shadow: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.4s;
  }

  &.nivel-1 {
    text-align: left;
    padding: 0;

    > a {
      //display: block;
      padding: 30px;
      min-height: 156px;

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }

    .b-bloque {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;

      img {
        max-height: 50px;
        margin-right: 15px;
      }
    }
  }

  @media (max-width: @screen-xs - 1) {

    padding-top: 0;

    a {
      min-height: 0;

      .b-bloque {
        flex-direction: column;
        text-align: center;

        img {
          margin: 5px 0 0;
          max-height: 60px;
        }

        h4 {
          font-size: 18px;
          padding: 5px;
        }
      }

      .b-boton .btn {
        font-size: 14px;
        padding: 10px !important;
      }
    }

    &.nivel-1 {
      a {
        padding: 10px;
        min-height: 0;

        .b-bloque {
          flex-direction: column;
          text-align: center;

          img {
            margin: 0;
          }

          h4 {
            font-size: 18px;
            height: 45px;
            padding: 5px;
          }
        }
      }
    }
  }
}