@media (min-width: 992px) {
  .sidebar {
    pointer-events: auto;
  }
  .sidebar-toggle {
    display: none;
  }
  .navbar-brand-center {
    text-align: left;
    width: auto;
    float: left;
    position: relative;
  }
  .sidebar-left {
    display: block;
    left: 0;
    width: 250px;
  }
  .sidebar-right {
    display: block;
    right: 0;
    left: auto;
    width: 250px;
  }
  .sidebar-right-in .sidebar-left {
    -webkit-transform: translate3d(0, 0, 0) !important;
    -moz-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
  }
  .sidebar-left-in .sidebar-right {
    -webkit-transform: translate3d(0, 0, 0) !important;
    -moz-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
  }
  .has-sidebar-left .app,
  .sidebar-left-in .app {
    width: auto;
    margin-left: 250px;
    left: 0;
    -webkit-transform: translate3d(0, 0, 0) !important;
    -moz-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
    position: relative;
    -webkit-transition: -webkit-transform 0 ease;
    -moz-transition: -moz-transform 0 ease;
    transition: transform 0 ease;
  }
  .has-sidebar-right .app,
  .sidebar-right-in .app {
    width: auto;
    margin-right: 250px;
    right: 0;
    -webkit-transform: translate3d(0, 0, 0) !important;
    -moz-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
    position: relative;
    -webkit-transition: -webkit-transform 0 ease;
    -moz-transition: -moz-transform 0 ease;
    transition: transform 0 ease;
  }
}

@media (min-width: 1200px) {
  .sidebar-left {
    width: 300px;
  }
  .sidebar-right {
    width: 300px;
  }
  .has-sidebar-left .app,
  .sidebar-left-in .app {
    margin-left: 300px;
  }
  .has-sidebar-right .app,
  .sidebar-right-in .app {
    margin-right: 300px;
  }
}

.sidebar-right {
  background-color: @bg-color-dark;
  color: @font-color-light;
  font-size: 20px;
}
.sidebar .list-group-item.active,
.sidebar .list-group-item.active:focus {
  background-color: @bg-color-grey;
  color: @font-color-base;
  border-color: @border-color-base;
}
.sidebar .list-group-item {
  background-color: @bg-color-dark;
  color: @font-color-light;
  border-color: @border-color-grey;
  padding: 15px 20px;
}
.sidebar .list-group-item .fa {
  color: @color-secondary;
  width: 20px;
}
.sidebar .list-group-item.active .fa,
.sidebar .list-group-item.active:focus .fa {
  color: @color-secondary;
}