.sensor {
  width: 100%;
  background-color: @bg-color-grey;
  //border-bottom: 1px solid @border-color-grey;
  min-height: 40px;
  height: auto;
  padding: 8px 0 0;
  clear: both;
  float: none;
  min-width: 100px;
  margin: 0 0 2px 0;

  &:last-child {
    margin-bottom: 0;
  }

  &.clickable {
    transition: all 200ms;

    &:hover {
      background-color: darken(@bg-color-grey, 3);
      transition-duration: 0ms;
    }
  }

  .contenedor {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .nombre {
    font-size: 16px;
    color: @font-color-grey;
    padding-left: 24px;
    text-transform: none;
    font-weight: @font-weight-regular;
    min-height: 24px;
    min-width: 24px;
    background-repeat: no-repeat;
    background-position: 0% 48%;
    background-size: 18px;
  }

  .estado {

    .glyphicon {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      margin-right: .5em;
      text-align: center;
      background: transparent;
      color: @font-color-grey;

      &-alert {
        color: @font-color-light;
        background-color: @color-alert;
      }

      &-ok {
        color: @font-color-light;
        background-color: @color-success;
      }

      &-off {
        color: @font-color-light;
        background-color: @color-muted;
      }
    }
  }

  .valor {
    padding-left: 25px;
    padding-top: 2px;
    font-size: 15px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: 18px;
    min-height: 18px;
  }

  .timestamp {
    text-align: center;
    color: @font-color-grey;
    min-height: 20px;
  }

  .bateria {
    width: 30px;
    height: auto;
    position: relative;
    float: right;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    min-height: 25px;

    @media (max-width: @screen-xs - 1) {
      position: absolute;
      top: -40px;
      right: 15px;
    }
  }

  .imagen {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: text-top;
    max-width: 100px;

    img {
      max-width: 100%;
    }
  }

  @media (max-width: @screen-xs - 1) {
    padding: 8px 10px;
    border-bottom: 1px solid @bg-color-grey;
  }
}




.sensor-row .clase.medicion,
.sensor .nombre.medicion {
  background-image: url(../images/icon_medicion.png);
}
.sensor-row .clase.matricula,
.sensor .nombre.matricula {
  background-image: url(../images/icon_matricula.png);
}
.sensor-row .clase.foto,
.sensor .nombre.foto {
  background-image: url(../images/icon_imagen.png);
}
.sensor-row .clase.estado,
.sensor .nombre.estado {
  background-image: url(../images/icon_status.png);
}

.table .sensor-row td {
  padding: 10px 15px 7px;
  vertical-align: middle;
}
.sensor-detalle .sensor-graficos {
  background-color: @bg-color-light;
  margin: 0;
  //border-bottom: 1px solid @border-color-grey;
}

.sensor-detalle .sensor-graficos #divAngularGaugeChart {
  padding-top: 40px;
}

.sensor-detalle .ultima-medicion {
  background-color: @bg-color-grey;
  text-align: center;
  padding: 10px;
  margin-bottom: 15px;

  @media (max-width: @screen-xs - 1) {
    text-align: left;
  }
}

.sensor-detalle .ultima-medicion .nombre {
  color: @font-color-base;
  padding-right: 60px;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  text-align: left;
}

.sensor-detalle .ultima-medicion .datos {
  margin-top: -20px;
}

.sensor-detalle .ultima-medicion.bateria {
  background-repeat: no-repeat;
  background-position: 99%;

  @media (max-width: @screen-xs - 1) {
    background-position: calc(100% - 10px) 10px;
  }
}

.sensor-detalle .ultima-medicion .estado {
  background-repeat: no-repeat;
  padding-left: 30px;
  background-size: 20px;
}

.sensor-detalle .ultima-medicion.imagen {
  margin-bottom: 0;
  background: @bg-color-light;
}

.sensor-detalle .ultima-medicion.imagen img {
  max-width: 100%;
}

.sensor-detalle .historico {
  visibility: hidden;
}
.sensor-detalle .unidades {
  padding-left: 0;
}
.sensor-detalle .bateria-band {
  padding-right: 30px;
  padding-top: 3px;
  background-color: @bg-color-light;
  height: 20px;
  text-align: center;
  margin-bottom: 0 auto;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 55% 4px;
  background-size: 25px;
  min-height: 25px;
}
.sensor-detalle .indicador {
  background-color: @bg-color-grey;
  border: none;
  margin-bottom: 0;
  padding: 10px;
  border-bottom: 1px solid @border-color-grey;
}
.sensor-detalle .medicion {
  clear: both;
  height: 34px;
  //border-bottom: 1px solid @border-color-grey;
  background-color: @bg-color-light;
}
.sensor-detalle .medicion .historico {
  visibility: visible;
}
.sensor-detalle .medicion .valor {
  background-repeat: no-repeat;
  background-position: left;
  background-size: 18px;
  padding: 7px 0 7px 25px;
  margin-left: 7px;
}
.sensor-detalle .medicion .timestamp {
  padding: 7px 7px 0;
}
.sensor-detalle .medicion .imagen {
  display: table;
  height: 34px;
}
.sensor-detalle .medicion .imagen a {
  display: table-cell;
  vertical-align: middle;
}
.sensor-detalle .medicion .imagen-medicion {
  max-height: 34px;
  max-width: 100px;
  height: auto;
}
.sensor-detalle .sensor {
  background-color: @bg-color-light;
}
.titulo-tabla {
  padding: 10px;
  color: @font-color-base;
  background-color: @bg-color-grey;
  font-size: 18px;
  //border-bottom: 1px solid @border-color-grey;
  font-weight: @font-weight-bold;
  text-transform: uppercase;
}
.sensor-detalle .sensor-imagen,
.sensor-detalle .sensor-texto,
.sensor-detalle .sensor-chart {
  display: none;
}
.sensor-detalle .estado-chart {
  display: none;
}
.sensor-detalle .espacio-detalle {
  background-color: @bg-color-grey !important;
  min-height: 200px;
}
.sensor-detalle .espacio-detalle .oculto {
  color: grey;
  float: left;
  margin-left: 0;
}
.sensor-detalle .espacio-detalle .mostrar {
  color: @font-color-light;
  float: left;
  width: 100%;
  margin-top: -17px;
  pointer-events: none;
}
.sensor-detalle .espacio-detalle .historico.mostrar {
  margin-top: 0;
}
.sensor-detalle .espacio-detalle .ultimo.oculto {
  float: right;
  margin-right: 0;
  margin-top: -17px;
}
.sensor-detalle .espacio-detalle .top-arrow {
  background-image: url(images/arrow-up.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 10px;
  background-size: 20px;
}
.sensor-detalle .espacio-detalle .sensor-bottom {
  background-color: @color-primary;
  color: @font-color-light;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
  height: 24px;
  padding-top: 4px;
}
.sensor-detalle .espacio-detalle.medicion {
  width: 100%;
  display: table;
}
.sensor-detalle .espacio-detalle.medicion .sensor-chart,
.sensor-detalle .espacio-detalle.medicion .estado-chart {
  display: block;
  color: @font-color-grey;
  text-align: center;
}
.sensor-detalle .espacio-detalle.medicion .sensor-chart .estado,
.sensor-detalle .espacio-detalle.medicion .estado-chart .estado {
  margin-bottom: 1px;
  font-size: 18px;
  height: 25px;
}
.sensor-detalle .espacio-detalle.medicion .estado-no-chart {
  display: none;
}
.sensor-detalle .espacio-detalle.matricula,
.sensor-detalle .espacio-detalle.estado {
  color: @font-color-grey;
}
.sensor-detalle .espacio-detalle.matricula .sensor-texto,
.sensor-detalle .espacio-detalle.estado .sensor-texto {
  display: block;
  padding-bottom: 10px;
}
.sensor-detalle .espacio-detalle.matricula .sensor-texto .valor,
.sensor-detalle .espacio-detalle.estado .sensor-texto .valor {
  background-color: @bg-color-light;
  width: 200px;
  height: 50px;
  margin: 0 auto;
  padding: 9px;
  font-size: 24px;
  //border: 1px solid @border-color-grey;
  text-align: center;
}
.sensor-detalle .espacio-detalle.foto .sensor-imagen {
  display: block;
}
.sensor-detalle .espacio-detalle.foto .sensor-imagen .imagen-medicion {
  width: 100%;
  padding: 0 15px;
}
.sensor-detalle .espacio-detalle.foto .sensor-bottom {
  background-image: url(images/icon_imagen.png);
  background-position: 25%;
}
.sensor-detalle .espacio-detalle .estado {
  text-align: center;
  height: 20px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 18px;
  width: 180px;
  margin: 4px auto;
  padding-top: 1px;
}

.sensor-row .nombre {
  font-weight: @font-weight-bold;
}
.sensor-row .clase {
  background-repeat: no-repeat;
  background-position: 15px;
  padding-left: 50px !important;
}

@media (max-width: 768px) {
  .sensor {
    padding-bottom: 8px;

    .timestamp {
      text-align: left;
    }
  }

}

@media (max-width: 480px) {
  .sensor-detalle .ultima-medicion .datos {
    margin-top: 5px;
  }
}