.table-hover>tbody>tr:hover, .table>tbody>tr.active>td, .table>tbody>tr.active>th, .table>tbody>tr>td.active, .table>tbody>tr>th.active, .table>tfoot>tr.active>td, .table>tfoot>tr.active>th, .table>tfoot>tr>td.active, .table>tfoot>tr>th.active, .table>thead>tr.active>td, .table>thead>tr.active>th, .table>thead>tr>td.active, .table>thead>tr>th.active {
  background-color: rgba(255,255,255,0.2) !important;
}

.table-hover>tbody>tr.table-filters:hover {
  background-color: transparent !important;
}

.table-filters th {
  padding: 0 !important;
  position: relative;
  border: none;
}

.table-filters input {
  width: 100%;
  background-color: @color-secondary-light;
  color: @font-color-grey;
  padding-left: 10px;
  margin-bottom: 15px;
}

.table-filters span.glyphicon {
  position: absolute;
  height: 39px;
  width: 35px;
  background-color: @color-secondary;
  color: @font-color-light;
  padding: 12px 10px;
  right: 1px;
  top: 1px;
}

.table-filters .row-sort {
  border: none;
}

.table-filters .row-sort input {
  margin: 0;
  font-weight: @font-weight-regular;

  @media (max-width: @screen-xs - 1) {
    padding-right: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.table-filters .row-sort-check {
  background: @bg-color-grey;
  text-align: center;
  margin: 0 auto;
  padding: 0 20px 0 20px;
  min-width: 60px;
}

.table-filters .row-sort-check input {
  display: block;
}

.table-filters .row-sort span.glyphicon {
  right: 37px;
  height: 40px;
  top: 0;
}

.table-filters .row-sort span.glyphicon-search {
  background: transparent;
  color: darkgrey;
}

.table-filters .row-sort {
  input[type="search"] {
    + .glyphicon {
      display: none;
    }
  }

  a.sort {
    span.glyphicon {
      right: 0;
      color: @font-color-light;
      background-color: @color-secondary;
    }

    &:hover {
      span.glyphicon {
        background-color: darken(@color-secondary, 5);
      }
    }
  }
}

.table .table-check {
  text-align: center;
  margin: 0 auto;
  padding: 8px 0 0 20px;
}

.table .table-check input {
  height: 14px !important;
  display: block;
}

.table .table-details {
  background: @bg-color-grey;
}

.table td {
  background: @bg-color-light;
  color: @font-color-base;
  border: none;
  padding: 12px;
  border-right: 1px solid @bg-color-grey;
  border-top: 1px solid @bg-color-grey;

  &:last-child {
    border-right-width: 0;
  }
}

//.table td.ng-binding:hover {
//  opacity: 0.8;
//}

table tr.spacer {
  background-color: transparent;
  height: 5px;
}

table tr.spacer-small {
  background-color: transparent;
  height: 2px;
}



.table.no-borders {
  > tbody, > tfoot, > thead {
    > tr {
      > td, > th {
        border-top: 0;
      }
    }
  }
}

@media (max-width: @screen-xs - 1) {
  .horizontal-scroll {
    width: 100%;
    overflow-x: scroll;
  }
}
