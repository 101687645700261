.navbar {
  background-color: @header-bg-color;
  color: @font-color-light;
}
.navbar.nav-despliegue,
.navbar.nav-sensor {
  background-color: @color-primary;
  font-size: 16px;
  font-weight: 500;
  color: @font-color-light;
}
.navbar.nav-despliegue .btn,
.navbar.nav-sensor .btn {
  color: @font-color-light;
}
.navbar.nav-despliegue .center-logo,
.navbar.nav-sensor .center-logo {
  padding-top: 14px;
}
.navbar.nav-sensor {
  background-color: @color-secondary;
}
.navbar-app .btn {
  color: @color-primary;
}
.navbar .center-logo {
  position: absolute;
  width: 70%;
  padding-top: 11px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
}
.navbar .center-logo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
}
.has-navbar-top .app-body {
  padding-top: 0 !important;
}

.navbar-form .form-group .form-control.username {
  margin-bottom: 3px;
  background-repeat: no-repeat;
  background-position: 10px;
  padding-left: 40px;
  background-size: 20px;
  background-color: @bg-color-grey !important;
  background-image: url(../images/user_icon.png) !important;
}
.navbar-form .form-group .form-control.password {
  margin-bottom: 3px;
  background-repeat: no-repeat;
  background-position: 10px;
  padding-left: 40px;
  background-size: 20px;
  background-color: @bg-color-grey !important;
  background-image: url(../images/pass_icon.png) !important;
}

.navbar-brand {
  padding: 7px 0;
}

.navbar-default .navbar-nav > li > a {
  color: @font-color-light;

  &:hover,
  &:focus {
    color: @font-color-light;
  }
}

nav.navbar-default {
  border: none;
}

.navbar-default {

  .navbar-toggle, .navbar-toggle:focus, .navbar-toggle:hover {
    margin-right: 0;
    border: 0;
    background: transparent;

    .icon-bar {
      background-color: @font-color-light;
    }
  }
}

@media (max-width: @screen-sm - 1) {
  .menu-main:not(.authenticated) {
    display: none;
  }
}