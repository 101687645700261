.bateria.bateria-verde,
.bateria-band.bateria-verde {
  background-image: url(../images/battery_almost_full.png);
}
.bateria.bateria-naranja,
.bateria-band.bateria-naranja {
  background-image: url(../images/battery_medium.png);
}
.bateria.bateria-full,
.bateria-band.bateria-full {
  background-image: url(../images/battery_full.png);
}
.bateria.bateria-roja,
.bateria-band.bateria-roja {
  background-image: url(../images/battery_low.png);
}
.bateria.bateria-off,
.bateria-band.bateria-off {
  background-image: url(../images/battery_off.png);
}
.bateria-band.bateria-verde {
  color: @color-green;
}
.bateria-band.bateria-naranja {
  color: @color-orange;
}
.bateria-band.bateria-full {
  color: @color-success;
}
.bateria-band.bateria-roja {
  color: @color-alert;
}
.bateria-band.bateria-off {
  color: @color-muted;
}