.maps-url {
  width: 100%;
  display: block;
  padding: 5px 10px;
  border: 1px solid @color-primary;
  text-align: center;
  margin-top: 5px;
}

.esri-view {
    height: 500px;
    padding: 0;
    margin: 0;
}
