.breadcrumb {
  float: left;
  clear: both;
  padding: 15px 30px;
  margin-bottom: 0;
  list-style: none;
  background-color: @color-primary;
  display: inline-block;

  > li {
    display: inline-block;
    color: @font-color-light;

    a {
      color: @font-color-light;
    }

    + li:before {
      content: "/\00a0";
      padding: 0 5px;
      color: @font-color-light;
    }

    &.active,
    &.active a {
      color: @font-color-light;
      font-weight: @font-weight-bold;
      text-transform: none;
    }
  }
}

@media (max-width: @screen-xs - 1) {
  .breadcrumb {
    font-size: 14px;
    display: none;
  }
}