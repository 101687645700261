div.action-bar {
  float: right;

  button,
  a {
    background: @color-primary;
    border: 2px solid @color-primary;
    margin-bottom: 15px;
    color: @font-color-light;
    font-weight: @font-weight-regular;
    text-transform: uppercase;
    text-shadow: initial;
    transition: all 0.4s;

    .glyphicon {
      vertical-align: middle;
      padding-right: .5em;
      opacity: .5;
      transition: opacity .4s;
    }

    &:hover, &:active, &:focus {
      background: @bg-color-dark;
      border-color: @bg-color-dark;
      color: @font-color-light;

      //.glyphicon {
      //  opacity: 1;
      //}
    }
  }

  @media (min-width: @screen-md) {
    margin-top: -@title-height/2;
  }
}