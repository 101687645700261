.form-edit input {
  margin-bottom: 5px;
  color: @font-color-dark;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 10px;
  width: 100%;
}

.form-edit select {
  margin-left: 10px;
  width: 100%;

  -webkit-appearance: none;
  height: 40px !important;
  line-height: 40px !important;
  color: @font-color-dark;
  padding-left: 13px;
  border-radius: 0;
  background: @color-secondary-light;
  margin-bottom: 5px;
  border: none;
}

.form-edit button.btn {
  margin-top: 15px;
}

.form-edit .btn-default[disabled] {
  color: @font-color-dark;
}

.form-edit span.help {
  margin-left: 10px;
  margin-bottom: 20px;
  display: block;
  font-size: 14px;
}

.form-edit-table {
  display: table;
  width: 100%;
}

.form-edit-row {
  display:table-row;
}

.form-edit-name {
  display:table-cell;
  width: 20%;
  min-width: 150px;
}

.form-edit-value {
  display:table-cell;
  width: 80%;
}

.form-edit-name-xl {
  display:table-cell;
  width: 60%;
}

.form-edit-value-xs {
  display:table-cell;
  width: 40%;
}

.form-edit-value input,
.form-edit-value select {
  margin-left: 0;
}