.despliegues ul {
  padding: 0;
}
.despliegues li {
  list-style: none;
  margin-bottom: 2px;
  background-color: @bg-color-light;
  min-height: 60px;
  padding: 5px 10px 0 10px;
}
.despliegues-header {
  margin: 0;
}
.despliegues-header div {
  color: @font-color-light;
  text-transform: uppercase;
  text-align: center;
  height: 20px;
  font-size: 12px;
  letter-spacing: 1.1px;
  padding-top: 2px;
}
.despliegues-header .despliegue-header {
  background-color: #6bbda5;
}
.despliegues-header .sensores-header {
  background-color: #b5cb4c;
}
.despliegue .row div {
  background-repeat: no-repeat;
  padding-top: 30px;
  background-size: 20px;
  min-height: 20px;
  height: 60px;
  text-align: center;
  background-position: center 5px;
  font-size: 16px;
}
.despliegue .nombre {
  color: @font-color-base;
  padding-top: 4px;
  font-size: 16px;
}
.despliegue .grey {
  color: @font-color-grey;
}
.despliegue .sensores .row {
  padding-right: 5px;
}
.despliegue .sensores-ok {
  background-image: url(images/icon_check.png);
}
.despliegue .sensores-ok.grey {
  background-image: url(images/icon_check_line.png);
}
.despliegue .sensores-alarm {
  background-image: url(images/icon_alert.png);
}
.despliegue .sensores-alarm.grey {
  background-image: url(images/icon_alert_line.png);
}
.despliegue .sensores-off {
  background-image: url(images/icon_off.png);
}
.despliegue .sensores-off.grey {
  background-image: url(images/icon_off_line.png);
}

.despliegue-boxes {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 1 1 25%;
    max-width: 25%;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;

    @media (max-width: @screen-sm - 1) {
      flex: 1 1 50%;
      max-width: 50%;
      min-width: 0;
    }
  }
}

.despliegue-box {
  list-style: none;
  width: 100%;
  color: @font-color-dark;
  /*margin-bottom: @grid-gutter-width;*/
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: @cards-box-shadow;

  &:hover {
    box-shadow: @cards-box-shadow-hover;
  }

  @media (max-width: @screen-xs - 1) {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .despliegue-box-imagen, .despliegue-box-nombre, .despliegue-box-estados {
      flex: 1;
    }
  }
}

.despliegue-box-imagen {
  width: 100%;
  height: auto;
}

.despliegue-box-nombre {
  background-color: @bg-color-light;
  padding: 10px;
}

.despliegue-box-estados {
  overflow: hidden;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  @media (max-width: @screen-xs - 1) {
    height: auto;
    display: flex;
    padding: 10px;
    flex: 1;
    align-items: flex-end;
  }
}

.despliegue-box-estado {
  display: inline-block;
  margin-bottom: 10px;

  > span {
    position: relative;
    float: left;
    top: 0; left: 0;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: @font-color-light;

    &:nth-child(1) {
      background-color: @bg-color-grey;
    }
    &:nth-child(2) {
      background-color: darken(@bg-color-grey, 10);

      @media (max-width: @screen-xs - 1) {
        display: none;
      }
    }
  }

  &.despliegue-box-ok {
    &.tiene {
      > span {
        &:nth-child(1) {
          background-color: @color-success;
        }
        &:nth-child(2) {
          background-color: darken(@color-success, 10);
        }
      }
    }
  }

  &.despliegue-box-alarma {
    &.tiene {
      > span {
        &:nth-child(1) {
          background-color: @color-alert;
        }
        &:nth-child(2) {
          background-color: darken(@color-alert, 10);
        }
      }
    }
  }

  &.despliegue-box-off {
    &.tiene {
      > span {
        &:nth-child(1) {
          background-color: @color-muted;
        }
        &:nth-child(2) {
          background-color: darken(@color-muted, 10);
        }
      }
    }
  }

  @media (max-width: @screen-xs - 1) {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }
}