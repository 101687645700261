#masthead {
  > .navbar {
    box-shadow: @header-box-shadow;
  }

  .container > .navbar-header {
    margin-left: 0;
    margin-right: 0;

    > .navbar-brand {
      margin-left: 0;
    }

    > .navbar-right {
      margin-right: 0;
    }
  }
}