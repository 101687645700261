.filter-input input {
  background: @bg-color-light;
  border: 1px solid @bg-color-light;
  padding: 5px 10px;
  color: @font-color-grey;
}

.filter-input .glyphicon {
  margin-left: -25px;
  color: @font-color-grey;
  top: 3px;
}