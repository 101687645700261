.menu-user {
  @media (max-width: @screen-sm - 1) {
    text-align: center;
  }
}

.navbar-default .navbar-nav {

  .dropdown-user {
    padding: 0;
    display: block;
    font-size: 16px;

    span {
      height: @navbar-height;
      line-height: @navbar-height;
      vertical-align: middle;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .glyphicon {
      font-size: @font-size-large;
      color: @color-secondary;
    }

    .caret {
      border-top: 5px dashed;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      position: relative;
      height: auto;
      margin-left: 3px;
      margin-right: 12px;
    }

    .btn-user-menu {
      width: @navbar-height;
      text-align: center;
      background-color: @color-secondary;
      color: @font-color-light;
    }

    @media (max-width: @screen-xs - 1) {
      padding-left: 10px;
    }
  }


  > .open {

    .dropdown-menu {
      padding: 0;
      background-color: @color-secondary-dark;
      border: 0;
      box-shadow: @cards-box-shadow;

      .divider {
        margin: 0;
        height: 0;
      }

      > li > a {
        padding: 10px 15px;
        background-color: @color-primary;
        color: @font-color-light;
        text-align: center;

        &:hover {
          background-color: @color-primary;
          color: @font-color-light;
          background-image: none;
        }
      }

      @media (min-width: @screen-sm) {
        background-color: @color-secondary-dark;

        > li > a {
          background-color: @color-secondary-dark;
          color: @font-color-light;
          text-align: left;

          &:hover {
            background-color: @color-primary;
            color: @font-color-light;
          }
        }
      }
    }

    .caret {
      border-top: 0;
      border-bottom: 5px dashed;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }

    > a, > a:focus, > a:hover {
      background-color: @color-primary;
      color: @font-color-light;
    }
  }
}

//.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .open > a {
//  background-image: none;
//}